
import { Component, Vue } from 'vue-property-decorator'
import { TableItem } from '@/types/common'

@Component({
  name: 'List'
})
export default class List extends Vue {
  private searchInfo: {projectName: string} = {
    projectName: ''
  }

  private tableData: { tr: Array<TableItem>; data: Array<any> } = {
    tr: [
      {
        label: '项目名称',
        prop: 'projectName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '消防栓数量',
        prop: 'fireFightingAmount',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '监测数量',
        prop: 'monitorAmount',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备数量',
        prop: 'totalAmount',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '在线数量',
        prop: 'onlineAmount',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private loading = false

  page = 1
  size = 10
  total = 0

  created (): void {
    this.getList()
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<any> }>(this.$apis.hydrant.selectSumProjectList,
      {
        projectName: this.searchInfo.projectName,
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch (): void {
    this.page = 1
    this.getList()
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'hydrantMonitorDetailMap',
      params: { projectId: id }
    })
  }
}
